/**
 * @generated SignedSource<<bed967f9e8bc9b019116bce553a85dee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppNotificationType = "DEFAULT" | "ERROR" | "PROGRESS" | "SUCCESS";
export type AppNotificationsQuery$variables = {};
export type AppNotificationsQuery$data = {
  readonly appNotifications: ReadonlyArray<{
    readonly action: string | null;
    readonly autoDismiss: boolean | null;
    readonly entity: string | null;
    readonly id: string;
    readonly link: string | null;
    readonly message: string | null;
    readonly progress: number | null;
    readonly type: AppNotificationType;
  }>;
};
export type AppNotificationsQuery = {
  response: AppNotificationsQuery$data;
  variables: AppNotificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AppNotification",
    "kind": "LinkedField",
    "name": "appNotifications",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "action",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "entity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "autoDismiss",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "progress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppNotificationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppNotificationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "17e152851a4cee9a4ffa85f65aa62a11",
    "id": null,
    "metadata": {},
    "name": "AppNotificationsQuery",
    "operationKind": "query",
    "text": "query AppNotificationsQuery {\n  appNotifications {\n    type\n    id\n    action\n    entity\n    message\n    autoDismiss\n    progress\n    link\n  }\n}\n"
  }
};
})();

(node as any).hash = "c3616848e868c896ef4e4c654b3b403c";

export default node;
