/**
 *
 * TimePicker
 * @author Chad Watson
 *
 */

import { Dropdown } from "components/Dropdown";
import { BASE_VERTICAL_PADDING_LARGE_IN_REM } from "constants/index";
import {
  format,
  getHours,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns/esm/fp";
import moment from "moment";
import PropTypes from "prop-types";
import {
  applySpec,
  compose,
  concat,
  identity,
  lt,
  map,
  range,
  toString,
  when,
  __,
} from "ramda";
import React from "react";
import { mapProps, withHandlers, withProps } from "recompose";
import styled from "styled-components/macro";

export const HOURS = range(1, 12).concat(0);
export const MINUTES = range(0, 60);
export const PERIODS = {
  AM: "AM",
  PM: "PM",
};

const hourLabel = (value) => moment({ hours: value }).format("h");

const getPeriodFromDate = format("a");

const HOUR_OPTIONS = map(
  applySpec({
    value: identity,
    label: hourLabel,
  }),
  HOURS
);

const MINUTE_OPTIONS = map(
  applySpec({
    value: identity,
    label: compose(when(lt(__, 10), concat("0")), toString),
  }),
  MINUTES
);
const PERIOD_OPTIONS = Object.values(PERIODS);

export const parseTimeFromDateString = (dateString, utc) => {
  const asMoment = utc ? moment.utc(dateString) : moment.parseZone(dateString);

  return {
    hours: asMoment.hours() % 12,
    minutes: asMoment.minutes(),
    period: asMoment.format("A"),
  };
};

export const parseTimeFromDate = (date, utc) => ({
  hours: (utc ? date.getUTCHours() : date.getHours()) % 12,
  minutes: utc ? date.getUTCMinutes() : date.getMinutes(),
  period: getPeriodFromDate(date),
});

export const toStandardHours = (hours, period) =>
  period === PERIODS.PM ? hours + 12 : hours;

const TimePicker = ({
  id,
  hours,
  minutes,
  period,
  timeZone,
  timeZones,
  onHoursChange,
  onMinutesChange,
  onTimePeriodChange,
  onTimeZoneChange,
  className,
}) => (
  <Root className={className}>
    <TimeFields>
      <TimeDropdown
        id={`${id}-hours`}
        options={HOUR_OPTIONS}
        selectedIndex={
          hours !== null
            ? HOUR_OPTIONS.findIndex(({ value }) => value === hours)
            : null
        }
        onChange={onHoursChange}
        label=""
      />
      <TimeDropdown
        id={`${id}-minutes`}
        options={MINUTE_OPTIONS}
        selectedIndex={
          minutes !== null
            ? MINUTE_OPTIONS.findIndex(({ value }) => value === minutes)
            : null
        }
        onChange={onMinutesChange}
        label=""
      />
      <TimeDropdown
        id={`${id}-period`}
        options={PERIOD_OPTIONS}
        selectedIndex={PERIOD_OPTIONS.indexOf(period)}
        onChange={onTimePeriodChange}
      />
    </TimeFields>
    {onTimeZoneChange && (
      <TimeZoneDropdown
        id={`${id}-time-zone`}
        options={timeZones}
        selectedIndex={timeZone.map((tz) => timeZones.indexOf(tz)).getOrElse(0)}
        onChange={onTimeZoneChange}
      />
    )}
  </Root>
);

TimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  onHoursChange: PropTypes.func.isRequired,
  onMinutesChange: PropTypes.func.isRequired,
  onTimePeriodChange: PropTypes.func.isRequired,
  onTimeZoneChange: PropTypes.func,
  hours: PropTypes.oneOf(HOURS),
  minutes: PropTypes.oneOf(MINUTES),
  period: PropTypes.oneOf(Object.values(PERIODS)),
  timeZone: PropTypes.object,
  timeZones: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  hours: null,
  minutes: null,
  period: PERIODS.AM,
  timeZone: null,
  timeZones: [],
  defaultDateString: moment
    .utc({ hours: 8, minutes: 0, seconds: 0 })
    .toISOString(),
};

export default TimePicker;

export const TimePickerFromDateString = compose(
  withProps(({ dateString, defaultDateString, utc }) => {
    const date = utc
      ? moment.utc(dateString || defaultDateString)
      : moment.parseZone(dateString || defaultDateString);

    return {
      hours: date.hours() % 12,
      minutes: date.minutes(),
      period: date.format("A"),
    };
  }),
  withHandlers({
    onHoursChange:
      ({ onChange, dateString, defaultDateString, utc }) =>
      (selectedItem) => {
        if (!selectedItem) return;

        const momentMaker = utc ? moment.utc : moment.parseZone;
        const period = momentMaker(dateString || defaultDateString).format("A");
        const hours = toStandardHours(selectedItem.value, period);
        const minutes = momentMaker(dateString || defaultDateString).minutes();

        onChange(
          momentMaker(dateString).hours(hours).minutes(minutes).toISOString()
        );
      },
    onMinutesChange:
      ({ onChange, dateString, utc }) =>
      (selectedItem) => {
        if (!selectedItem) return;

        const momentMaker = utc ? moment.utc : moment.parseZone;
        const hours = dateString ? momentMaker(dateString).hours() : HOURS[0];
        const minutes = selectedItem.value;

        onChange(
          momentMaker(dateString).hours(hours).minutes(minutes).toISOString()
        );
      },
    onTimePeriodChange:
      ({ onChange, dateString, utc }) =>
      (period) => {
        if (!dateString) {
          return;
        }

        const momentMaker = utc ? moment.utc : moment.parseZone;
        const { hours, minutes } = momentMaker(dateString).toObject();

        if (period === PERIODS.AM && hours >= 12) {
          onChange(
            momentMaker(dateString)
              .hours(hours - 12)
              .minutes(minutes)
              .toISOString()
          );
        } else if (period === PERIODS.PM && hours <= 12) {
          onChange(
            momentMaker(dateString)
              .hours(hours + 12)
              .minutes(minutes)
              .toISOString()
          );
        }
      },
  })
)(TimePicker);

export const TimePickerFromMoment = compose(
  withHandlers({
    onHoursChange:
      ({ date, onChange }) =>
      (selectedItem) => {
        if (!selectedItem) return;

        const period = date.format("A");
        const hours = toStandardHours(selectedItem.value, period);

        onChange(moment(date).hours(hours).seconds(0));
      },
    onMinutesChange:
      ({ onChange, date }) =>
      (selectedItem) => {
        if (!selectedItem) return;
        onChange(moment(date).minutes(selectedItem.value).seconds(0));
      },
    onTimePeriodChange:
      ({ onChange, date }) =>
      (period) => {
        const hours = date.hour();

        if (period === PERIODS.AM && hours > 12) {
          onChange(
            moment(date)
              .hour(hours - 12)
              .seconds(0)
          );
        } else if (period === PERIODS.PM && hours <= 12) {
          onChange(
            moment(date)
              .hour(hours + 12)
              .seconds(0)
          );
        }
      },
  }),
  mapProps(({ date, onChange, ...rest }) => ({
    ...rest,
    hours: date.hours() % 12,
    minutes: date.minutes(),
    period: date.format("A"),
  }))
)(TimePicker);

const padTwoDigitTime = (number) => (number < 10 ? `0${number}` : `${number}`);

/** @type {(props: { scheduleTime: string; onChange: (newTime: string) => void, id?: string, className?: string }) => JSX.Element} */
export const TimePickerFromScheduleTime = ({
  scheduleTime,
  onChange,
  ...props
}) => {
  const currentHour = compose(
    (hours) => hours,
    (hours) => hours % 12,
    Number,
    (time) => time.substr(0, 2)
  )(scheduleTime);

  const currentMinute = compose(Number, (time) => time.substr(2, 2))(
    scheduleTime
  );

  const currentPeriod = compose(
    (hours) => (hours < 12 ? PERIODS.AM : PERIODS.PM),
    Number,
    (time) => time.substr(0, 2)
  )(scheduleTime);

  return (
    <TimePicker
      {...props}
      hours={currentHour}
      minutes={currentMinute}
      period={currentPeriod}
      onHoursChange={({ value: hour }) => {
        currentPeriod === PERIODS.AM
          ? onChange(
              `${padTwoDigitTime(hour)}${padTwoDigitTime(currentMinute)}`
            )
          : onChange(`${hour + 12}${padTwoDigitTime(currentMinute)}`);
      }}
      onMinutesChange={({ value: minute }) => {
        currentPeriod === PERIODS.AM
          ? onChange(
              `${padTwoDigitTime(currentHour)}${padTwoDigitTime(minute)}`
            )
          : onChange(`${currentHour + 12}${padTwoDigitTime(minute)}`);
      }}
      onTimePeriodChange={(period) => {
        period === PERIODS.AM
          ? onChange(
              `${padTwoDigitTime(currentHour)}${padTwoDigitTime(currentMinute)}`
            )
          : onChange(`${currentHour + 12}${padTwoDigitTime(currentMinute)}`);
      }}
    />
  );
};

const resetSeconds = setSeconds(0);

const enhance = compose(
  withHandlers({
    onHoursChange:
      ({ date, onChange }) =>
      ({ value: hours }) => {
        onChange(
          resetSeconds(
            setHours(toStandardHours(hours, getPeriodFromDate(date)))(date)
          )
        );
      },
    onMinutesChange:
      ({ onChange, date }) =>
      ({ value: minutes }) => {
        onChange(resetSeconds(setMinutes(minutes)(date)));
      },
    onTimePeriodChange:
      ({ onChange, date }) =>
      (period) => {
        if (period === PERIODS.AM && getHours(date) > 12) {
          onChange(resetSeconds(setHours(getHours(date) - 12)(date)));
        } else if (period === PERIODS.PM && getHours(date) <= 12) {
          onChange(resetSeconds(setHours(getHours(date) + 12)(date)));
        }
      },
  }),
  mapProps(({ date, onChange, utc, ...rest }) => ({
    ...rest,
    ...parseTimeFromDate(date, !!utc),
  }))
);

export const TimePickerFromDate = enhance(TimePicker);
const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const TimeFields = styled.div`
  display: flex;

  &:not(:only-child) {
    margin-right: 4px;
    margin-bottom: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
  }
`;
const TimeDropdown = styled(Dropdown)`
  flex: 1;

  &:not(:first-child) {
    margin-left: 2px;
  }
  &:not(:last-child) {
    margin-right: 2px;
  }
`;
const TimeZoneDropdown = styled(Dropdown)`
  width: auto;
`;
