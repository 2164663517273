import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import styled from "styled-components";
import Notification from "./Notification";
import { AppNotificationsQuery } from "./__generated__/AppNotificationsQuery.graphql";

const AppNotifications = () => {
  const data = useLazyLoadQuery<AppNotificationsQuery>(
    graphql`
      query AppNotificationsQuery {
        appNotifications {
          type
          id
          action
          entity
          message
          autoDismiss
          progress
          link
        }
      }
    `,
    {},
    { fetchPolicy: "store-only" }
  );
  const notifications = data.appNotifications ?? [];
  return (
    <NotificationContainer>
      {notifications.map((notification) => (
        <Notification key={notification.id} data={notification} />
      ))}
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 22rem;
`;

export default AppNotifications;
