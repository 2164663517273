import withClearButton from "components/Form/withClearButton";
import Tooltip from "components/Tooltip";
import { ESCAPE } from "containers/App/constants";
import React from "react";
import styled from "styled-components/macro";
import { noop } from "utils";
import BigField from "./BigField";
import DefaultField from "./Field";
import { themeGrayDark, themeTrueBlack } from "containers/AppTheme";

export const FIELD_TYPES = {
  DEFAULT: "default",
  BIG: "big",
};

const Field = withClearButton(
  React.forwardRef(
    ({ FieldComponent, openPicker, closePicker, id, onOpen, ...rest }, ref) => (
      <FieldWrapper disabled={rest.disabled}>
        <FieldComponent
          {...rest}
          ref={ref}
          onClick={openPicker}
          onBlur={closePicker}
          disabled={rest.disabled}
        />
        <Label htmlFor={id} onClick={openPicker} disabled={rest.disabled} />
      </FieldWrapper>
    )
  )
);

const FIELDS_MAP = {
  [FIELD_TYPES.DEFAULT]: DefaultField,
  [FIELD_TYPES.BIG]: BigField,
};

const FieldWrapper = styled.div`
  position: relative;

  & input {
    ${({ disabled }) => !disabled && `background: white !important;`}
    -webkit-text-fill-color: currentColor !important;
  }
`;
const Label = styled.label`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ disabled }) => disabled ? themeGrayDark : themeTrueBlack};
`;

/** @type {(props: {
 *   fieldType?: typeof FIELD_TYPES[keyof typeof FIELD_TYPES];
 *   children: Parameters<typeof Tooltip>[0]["children"];
 *   onOpen?: React.ButtonHTMLAttributes<any>["onClick"];
 *   onClose?: React.ButtonHTMLAttributes<any>["onClick"];
 *   zIndex?: number;
 *   closeOnSelect?: boolean
 * } & Parameters<typeof Field>[0]) => JSX.Element} */
const FieldWithPicker = React.forwardRef(
  (
    {
      fieldType = FIELD_TYPES.DEFAULT,
      children,
      onOpen = noop,
      onClose = noop,
      zIndex,
      dismissOnScroll = false,
      closeOnSelect = false,
      ...rest
    },
    ref
  ) => {
    const [pickerOpen, setPickerOpen] = React.useState(false);

    const handleClose = React.useCallback(
      (e) => {
        setPickerOpen(false);
        onClose(e);
      },
      [onClose]
    );

    const componentChildren = React.Children.map(children, (child) => {
      if (!!child && closeOnSelect) {
        // Passing closeFunc to children to call after selection has been made.
        return React.cloneElement(child, { closeFunc: handleClose });
      } else {
        return child;
      }
    });

    React.useEffect(() => {
      if (pickerOpen) {
        const handleKeyDown = ({ which }) => {
          if (which === ESCAPE) {
            handleClose();
          }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, [handleClose, pickerOpen]);

    return (
      <Tooltip
        anchor={({ registerAnchor }) => (
          <div ref={registerAnchor}>
            <Field
              {...rest}
              ref={ref}
              FieldComponent={FIELDS_MAP[fieldType]}
              openPicker={(e) => {
                if (!rest.disabled) {
                  setPickerOpen(true);
                  onOpen(e);
                }
              }}
              closePicker={handleClose}
            />
          </div>
        )}
        open={pickerOpen}
        onDismiss={handleClose}
        maxHeight="none"
        zIndex={zIndex}
        dismissOnScroll={dismissOnScroll}
      >
        {componentChildren}
      </Tooltip>
    );
  }
);

export default FieldWithPicker;
