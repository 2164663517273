import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

import { CloseCancelIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import { themeTrueBlack } from "containers/AppTheme";

export const CloseButton = styled(NakedButton)`
  padding: 0 10px;
  color: ${themeTrueBlack};
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`;

/** @type {(props: React.ButtonHTMLAttributes<any>) => JSX.Element} */
export const CloseButtonWithIcon = (props) => (
  <CloseButton {...props}>
    <CloseCancelIcon />
  </CloseButton>
);

CloseButtonWithIcon.propTypes = {
  onClose: PropTypes.func.isRequired,
};
