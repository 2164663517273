/**
 *
 * AddButton
 * @author Chad Watson
 *
 */
import { AddIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import {
  defaultThemeBoxShadow,
  themeGrayDark,
  themeTrueBlack,
} from "containers/AppTheme";
import React, { forwardRef } from "react";
import styled from "styled-components/macro";

const Button = styled(NakedButton)`
  flex-shrink: 0;
  flex-grow: 0;
  width: 2em;
  height: 2em;
  margin-left: 7px;
  border-radius: 50%;
  border: 1px solid;
  background: white;
  color: ${({ disabled }) =>
    disabled ? themeGrayDark : themeTrueBlack};
  font-size: 10px;
  vertical-align: bottom;

  &:hover {
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.05)")};
    box-shadow: ${({ disabled }) =>
      disabled ? "none" : defaultThemeBoxShadow};
  }

  &:active {
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.1)")};
  }
`;
const Icon = styled(AddIcon)`
  display: block;
  margin: 0 auto;
`;
/** @type {(props: React.ButtonHTMLAttributes<any>) => JSX.Element} */
const AddButton = forwardRef((props, ref) => (
  <Button {...props} ref={ref}>
    <Icon />
  </Button>
));
export default AddButton;
