import MediaQuery from "components/Breakpoints/MediaQuery";
import EditableLabel from "components/EditableLabel";
import { themeGrayDark, themeTrueBlack } from "containers/AppTheme";
import useBreakpoints from "hooks/useBreakpoints";
import React, { forwardRef, InputHTMLAttributes, Ref } from "react";
import styled from "styled-components/macro";
import { media } from "utils/styles";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  defaultFocused?: boolean;
  icon?: React.ReactNode;
  errorMessage?: string;
};

const NameField = forwardRef<HTMLInputElement, Props>(
  (
    {
      id = "name-field",
      value = "",
      onChange,
      onBlur,
      defaultFocused = false,
      icon,
      placeholder,
      min,
      max,
      disabled = false,
      maxLength,
      errorMessage,
      ...rest
    },
    ref
  ) => {
    const { breakpointNames } = useBreakpoints();

    return (
      <Root>
        <Flex>
          {!!icon && (
            <MediaQuery targetBreakpoint={breakpointNames.SMALL}>
              <IconWrapper
                htmlFor={id}
                error={!!errorMessage}
                disabled={disabled}
              >
                {icon}
              </IconWrapper>
            </MediaQuery>
          )}
          <StyledEditableLabel
            {...rest}
            ref={ref as Ref<HTMLInputElement>}
            id={id}
            value={value}
            min={min}
            max={max}
            maxLength={maxLength}
            defaultFocused={defaultFocused}
            onChange={onChange}
            onCommit={onBlur!}
            placeholder={placeholder}
            disabled={disabled}
          />
        </Flex>
      </Root>
    );
  }
);

export default NameField;

const Root = styled.div`
  font-size: 28px;

  ${media.small`
    font-size: 36px;
  `};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const IconWrapper = styled.label<{ error: boolean; disabled?: boolean }>`
  margin-right: 0.35em;
  color: ${({ theme, error, disabled }) =>
    error ? theme.red600 : disabled ? theme.primary200 : theme.primary500};
  font-size: 0.95em;

  svg {
    display: block;
  }
`;

const StyledEditableLabel = styled(EditableLabel)`
  height: 1.25em;
  color: ${({ disabled }) => (disabled ? themeGrayDark : themeTrueBlack)};
  font-size: 1em;
  font-weight: bold;
  line-height: 1.25;

  &[disabled] {
    -webkit-text-fill-color: currentColor;
  }
`;
