import DmpIcon from "common/components/DmpIcon";
import noop from "common/utils/universal/noop";
import { CheckmarkIcon, CloseCancelIcon } from "components/Icons";
import LoadingSpinner from "components/LoadingSpinner";
import NakedButton from "components/NakedButton";
import {
  BASE_LIST_ITEM_HEIGHT_IN_REM,
  BASE_VERTICAL_PADDING_LARGE_IN_REM,
  BORDER_RADIUS,
} from "constants/index";
import { ESCAPE } from "containers/App/constants";
import {
  themeGrayAccent,
  themeGrayXlight,
  themePrimary,
  themeSecondary,
  themeSuccess,
  themeTrueBlack,
} from "containers/AppTheme";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SaveStatus } from "securecom-graphql/client";
import styled, { css, useTheme } from "styled-components/macro";
import { seconds } from "utils/dates";
import { media } from "utils/styles";
import { CloseButton } from "./CloseButton";
import FormWrapper from "./FormWrapper";
import messages from "./messages";

export { default as AddButton } from "./AddButton";
export { default as AllRowsToggles } from "./AllRowsToggles";
export { default as BigField } from "./BigField";
export { default as BigPasswordField } from "./BigPasswordField";
export { default as ClearFormButton } from "./ClearFormButton";
export { CloseButton, CloseButtonWithIcon } from "./CloseButton";
export { default as DateField } from "./DateField";
export { default as DeleteButton } from "./DeleteButton";
export { default as Errors } from "./Errors";
export { default as Field } from "./Field";
export { default as FieldValidationError } from "./FieldValidationError";
export { default as FieldWithLabel } from "./FieldWithLabel";
export { default as FieldWithSuggestions } from "./FieldWithSuggestions";
export { default as FieldWithValidation } from "./FieldWithValidation";
export { default as Help } from "./Help";
export { default as HelpTitle } from "./HelpTitle";
export { default as Label } from "./Label";
export { default as NameField } from "./NameField";
export { default as NumberField } from "./NumberField";
export { default as ResetButton } from "./ResetButton";
export { default as SaveButton } from "./SaveButton";
export { default as StandaloneFormControlBar } from "./StandaloneFormControlBar";
export { default as TimeField } from "./TimeField";
export { default as withErrorMessage } from "./withErrorMessage";

export const STATUSES = {
  LOADING: "LOADING",
  SAVING: "SAVING",
  SAVED: "SAVED",
  DELETING: "DELETING",
  DELETED: "DELETED",
  ERROR: "ERROR",
  IDLE: "IDLE",
};

export const PROCESSING_STATUSES = [
  STATUSES.LOADING,
  STATUSES.SAVING,
  STATUSES.DELETING,
];

export const STATUS_MESSAGES = {
  [STATUSES.LOADING]: messages.loading,
  [STATUSES.SAVING]: messages.saving,
  [STATUSES.SAVED]: messages.saved,
  [STATUSES.DELETING]: messages.deleting,
  [STATUSES.DELETED]: messages.deleted,
  [STATUSES.ERROR]: messages.error,
  [STATUSES.IDLE]: messages.idle,
};

type FormProps = {
  children: React.ReactNode;
  actionButtons?: React.ReactNode[];
  status?: SaveStatus | keyof typeof STATUSES | null;
  onClose?: () => void;
  onClearSavedStatus?: () => void;
  autoClearStatus?: boolean;
  className?: string;
  disabled?: boolean;
};

function Form({
  actionButtons = [],
  children,
  status,
  onClose = noop,
  onClearSavedStatus = noop,
  autoClearStatus,
  className,
  disabled,
}: FormProps) {
  const hasActionRow = !!onClose || !!actionButtons.length;

  React.useEffect(() => {
    if ((onClearSavedStatus && status === STATUSES.SAVED) || autoClearStatus) {
      const timeout = setTimeout(onClearSavedStatus, seconds(5));
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [status]); // eslint-disable-line

  React.useEffect(() => {
    if (onClose) {
      const handleKeyDown = ({ which }) => {
        if (which === ESCAPE) {
          onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [onClose]);

  const theme = useTheme();

  const statusMessage = status
    ? STATUS_MESSAGES[status]
    : STATUS_MESSAGES[STATUSES.IDLE];

  return (
    <FormWrapper
      hasActionRow={hasActionRow}
      className={className}
      disabled={disabled}
    >
      {hasActionRow && (
        <Actions>
          <ActionsContainer>
            {!!onClose && (
              <CloseButton onClick={onClose} type="button">
                <CloseCancelIcon />
              </CloseButton>
            )}
            {Boolean(statusMessage) && status && status !== STATUSES.IDLE ? (
              <LoadingMessage>
                <LoadingMessageIconWrapper>
                  {status === STATUSES.SAVED ? (
                    <DmpIcon icon="checkmark" color={theme.success} />
                  ) : status === STATUSES.ERROR ? (
                    <DmpIcon icon="radial_alert" color={theme.warning} />
                  ) : (
                    <LoadingSpinner />
                  )}
                </LoadingMessageIconWrapper>
                <FormattedMessage {...statusMessage} />
              </LoadingMessage>
            ) : null}
          </ActionsContainer>
          <ActionsRight>{actionButtons}</ActionsRight>
        </Actions>
      )}
      <ContentWrapper>{children}</ContentWrapper>
    </FormWrapper>
  );
}

export default Form;

const sharedCellStyles = css`
  color: ${themeTrueBlack};
  &:first-child {
    width: 100%;
    padding-left: 5px;
    text-align: left;
  }
`;
const sharedTitleStyles = css`
  color: ${themeTrueBlack};
  padding-bottom: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;

  ${media.small`
    font-size: 24px;
  `};
`;

export const FormHeader = styled.header`
  margin-bottom: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
`;

export const FormTitle = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${themeTrueBlack};
  font-size: 28px;
  font-weight: bold;
  line-height: 1.25;

  ${media.small`
    font-size: 36px;
  `};
`;

export const FormTitleIconWrapper = styled.span`
  color: ${themeTrueBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.3em;
  line-height: inherit;
`;

export const FormDescription = styled.p`
  margin: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem 0 0;
  font-size: 400;
  color: ${themeTrueBlack};
`;

export const Section = styled.div<{
  disabled?: boolean;
  omitBottomMargin?: boolean;
}>`
  position: relative;
  margin-bottom: ${({ omitBottomMargin }) =>
    omitBottomMargin ? 0 : BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
  color: ${({ disabled, theme }) =>
    disabled ? theme.grayDark : theme.trueBlack};
`;

export const SectionHeader = styled.div<{
  disabled?: boolean;
  omitBottomMargin?: boolean;
  omitBottomPadding?: boolean;
  omitTopPadding?: boolean;
}>`
  display: flex;
  align-items: flex-end;
  margin: 0 0 ${({ omitBottomMargin }) =>
    omitBottomMargin ? 0 : BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
  padding: ${({ omitTopPadding, omitBottomPadding }) =>
    `${omitTopPadding ? 0 : BASE_VERTICAL_PADDING_LARGE_IN_REM}rem 0 ${
      omitBottomPadding ? 0 : BASE_VERTICAL_PADDING_LARGE_IN_REM
    }rem 5px;`}
  border-bottom: 2px solid ${themeGrayAccent};
    border-bottom: ${({ disabled, theme }) =>
      disabled
        ? `2px solid ${theme.grayXlight}`
        : `2px solid ${theme.grayAccent}`};
  color: ${({ disabled, theme }) =>
    disabled ? theme.grayDark : theme.trueBlack};
`;

export const SectionTitle = styled.h4<{ disabled?: boolean }>`
  ${sharedTitleStyles};
  padding: 0;
  margin: 0;
  line-height: 1;
  color: ${({ disabled, theme }) =>
    disabled ? theme.grayMediumLight : theme.gray};
`;

export const SectionContent = styled.div`
  position: relative;
`;

export const Fields = styled.div`
  width: 100%;

  & > * {
    width: 100%;
    margin-bottom: 5px;
  }

  ${media.small`
    display: flex;
    align-items: flex-end;

    & > :not(:first-child) {
      margin-left: 5px;
    }
    & > :not(:last-child) {
      margin-right: 5px;
    }
  `};
`;
export const TableRow = styled.tr`
  height: ${BASE_LIST_ITEM_HEIGHT_IN_REM}rem;

  &:nth-child(even) {
    background: ${themeGrayXlight};
  }
`;
export const HeaderCell = styled.th`
  ${sharedCellStyles};
  padding-top: 0;
  padding-bottom: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
  vertical-align: bottom;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  white-space: pre;

  &:first-child {
    ${sharedTitleStyles};
    display: block;
    padding-right: 0;

    ${media.small`
      display: table-cell;
    `};
  }
`;
export const TableCell = styled.td`
  ${sharedCellStyles};
  padding-bottom: 0;
  vertical-align: middle;
`;
export const HeaderButton = styled(NakedButton)`
  &:hover {
    color: ${themePrimary};
  }
`;
export const Footnote = styled.p`
  position: relative;
  padding-left: 0.75em;
  margin: 0.8em 0;
  color: ${themeTrueBlack};
  font-size: 12px;
  line-height: 1.25;
`;
export const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

const ContentWrapper = styled.div`
  padding: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem 15px;

  ${media.small`
    padding: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem 30px ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
  `};
`;
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem 15px 0;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  background: white;

  & button {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ActionsRight = styled(ActionsContainer)`
  justify-content: flex-end;
`;
export const LoadingMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${themeSecondary};
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
`;
export const LoadingMessageIconWrapper = styled.div`
  margin-right: 5px;
  font-size: 1.1em;
  line-height: 1;
`;
export const SavedIcon = styled(CheckmarkIcon)`
  color: ${themeSuccess};
`;
export const SecondaryText = styled.span`
  color: ${themeTrueBlack};
  font-size: 0.875rem;
  font-weight: 500;
`;
