import { UnthemedButton } from "components/Button";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ThemeProvider } from "styled-components/macro";
import messages from "./messages";

const ClearFormButton = (props) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.trueBlack,
      opposingColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton {...props} size="small" variant="outline">
      <FormattedMessage {...messages.clear} />
    </UnthemedButton>
  </ThemeProvider>
);

export default ClearFormButton;
