import NakedButton from "components/NakedButton";
import { BORDER_RADIUS } from "constants/index";
import {
  themeGrayAccentLight,
  themeGrayDark,
  themePanelBorder,
  themeTrueBlack,
} from "containers/AppTheme";
import React, { forwardRef } from "react";
import styled, { css } from "styled-components/macro";
import { media } from "utils/styles";

export const Button = styled(NakedButton)<{ space?: boolean }>`
  display: block;
  position: relative;
  height: 32px;
  padding: 0 15px;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  background: white;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.06em;
  white-space: pre;

  ${({ space }) =>
    space &&
    css`
      &:not(:last-child) {
        margin-right: 5px;
      }
    `};

  &[disabled] {
    color: ${themeGrayDark};
  }

  &:enabled {
    color: ${themeTrueBlack};

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: scale(1.02);
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
    }
  }

  ${media.smallMax`
    flex: 1;

    & button {
      width: 100%;
    }
  `};
`;

export const LargeButton = styled(NakedButton)<{ space?: boolean }>`
  display: block;
  position: relative;
  min-height: 38px;
  padding: 0 15px;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  background: white;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  max-width: fit-content;

  ${({ space }) =>
    space &&
    css`
      &:not(:last-child) {
        margin-right: 5px;
      }
    `};

  &[disabled] {
    color: ${themeGrayDark};
  }

  &:enabled {
    color: ${themeTrueBlack};

    &:hover {
      border: 1px solid ${themeGrayAccentLight};
    }

    &:active {
      border: 1px solid ${themeGrayAccentLight};
    }
    &:focus,
    :focus-visible,
    :focus-within {
      border: 1px solid ${({ theme }) => theme.primary500};
      outline: 1px solid ${({ theme }) => theme.primary200};
    }
  }

  ${media.smallMax`
    flex: 1;

    & button {
      width: 100%;
    }
  `};
`;

const InnerWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.span`
  display: flex;
  flex-wrap: wrap;
`;
const IconLeftWrapper = styled(Wrapper)`
  margin-right: 0.45em;
  font-size: 1.2em;
`;
const IconRightWrapper = styled(Wrapper)`
  margin-left: 0.45em;
  font-size: 1.2em;
`;

type Props = React.ComponentProps<"button"> & {
  icon?: React.ReactNode;
  space?: boolean;
};

const ActionButton = forwardRef(
  ({ icon, children, ...rest }: Props, ref: React.Ref<HTMLButtonElement>) => (
    <Button {...rest} ref={ref}>
      <InnerWrapper>
        {!!icon && <IconLeftWrapper>{icon}</IconLeftWrapper>}
        <Wrapper>{children}</Wrapper>
      </InnerWrapper>
    </Button>
  )
);

export default ActionButton;

export const DangerActionButton = styled(ActionButton)`
  &:enabled {
    color: ${({ theme }) => theme.red600};
  }

  &[disabled] {
    color: ${({ theme }) => theme.red100};
  }
`;

export const ActionButtonRightIcon = forwardRef(
  ({ icon, children, ...rest }: Props, ref: React.Ref<HTMLButtonElement>) => (
    <LargeButton {...rest} ref={ref}>
      <InnerWrapper>
        <Wrapper>{children}</Wrapper>
        {!!icon && <IconRightWrapper>{icon}</IconRightWrapper>}
      </InnerWrapper>
    </LargeButton>
  )
);
