/*
 * Notifications Messages
 *
 * This contains all the text for the Notifications.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  success: {
    id: "app.components.Notifications.success",
    defaultMessage: "Successful",
  },
  progress: {
    id: "app.components.Notifications.progress",
    defaultMessage: "In Progress",
  },
  error: {
    id: "app.components.Notifications.error",
    defaultMessage: "Failed",
  },
  unknown: {
    id: "app.components.Notifications.unknown",
    defaultMessage: "Unknown message",
  },
  view: {
    id: "app.components.Notifications.view",
    defaultMessage: "View",
  },
  dismiss: {
    id: "app.components.Notifications.dismiss",
    defaultMessage: "Dismiss",
  },
});
