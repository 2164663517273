/**
 *
 * withClearButton
 * @author Chad Watson
 *
 */

import { CloseCancelIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import { themeSecondary, themeTrueBlack } from "containers/AppTheme";
import * as React from "react";
import styled from "styled-components/macro";

export const FieldWrapper = styled.div`
  position: relative;
`;
export const ClearButton = styled((props) => (
  <NakedButton {...props}>
    <CloseCancelIcon />
  </NakedButton>
))`
  background-color: transparent;
  position: absolute;
  bottom: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: ${themeTrueBlack};
  font-size: 12px;
  transform: translateY(50%);

  &:hover,
  &:focus {
    color: ${themeSecondary};
  }
`;

const withClearButton = (Component) => {
  function ComponentWithClearButton(
    { onClear, disabled, value, ...rest },
    ref
  ) {
    const [hover, setHover] = React.useState(false);

    return (
      <FieldWrapper
        onMouseEnter={() => void setHover(true)}
        onMouseLeave={() => void setHover(false)}
      >
        <Component {...rest} ref={ref} disabled={disabled} value={value} />
        {!disabled && value && onClear && (hover || rest.ancestorHasFocus) && (
          <ClearButton
            type="button"
            onClick={onClear}
            data-testid="with-clear-button-button"
            ref={ref}
          />
        )}
      </FieldWrapper>
    );
  }

  return React.forwardRef(ComponentWithClearButton);
};

export default withClearButton;
