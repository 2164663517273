import NakedButton from "components/NakedButton";
import { themePrimary } from "containers/AppTheme";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import messages from "./messages";

type AllRowsTogglesProps = {
  onAllClick: () => void;
  onNoneClick: () => void;
  className?: string;
};

const AllRowsToggles = ({
  onAllClick,
  onNoneClick,
  className,
}: AllRowsTogglesProps) => (
  <Wrapper className={className}>
    <Button onClick={onAllClick}>
      <FormattedMessage {...messages.all} />
    </Button>
    <Separator>/</Separator>
    <Button onClick={onNoneClick}>
      <FormattedMessage {...messages.none} />
    </Button>
  </Wrapper>
);

export default AllRowsToggles;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
`;
const Button = styled(NakedButton)`
  color: ${themePrimary};
  font-weight: 600;
`;
const Separator = styled.span`
  display: inline-block;
  margin: 0 0.25em;
  font-weight: 400;
`;
