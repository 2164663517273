import React from "react";
import SvgIcon from "./SvgIcon";
const VIEW_SIZE = 64;

const DoorLockdownIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox={`0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
    enableBackground={`new 0 0 ${VIEW_SIZE} ${VIEW_SIZE}`}
  >
    <g>
      <rect fill="none" width="64" height="64" />
      <path d="M31.9,33.1a2,2,0,0,0,2-2v-4a2,2,0,0,0-4,0v4A2,2,0,0,0,31.9,33.1Z" />
      <path d="M28.9,58V54.2H6.9V4.1H38V20.7a8.1,8.1,0,0,1,4-1.8V2a2,2,0,0,0-2-2H4.9a2,2,0,0,0-2,2V56.2a2,2,0,0,0,2,2h24Z" />
      <path d="M55.5,39V28.7c0-3.4-2.4-6-5.5-6H43.7c-3.1,0-5.2,2.4-5.2,6V39a6,6,0,0,0-5.6,6V58a6,6,0,0,0,5.9,6H55.2a6,6,0,0,0,5.9-6V45A6,6,0,0,0,55.5,39ZM43.7,26.7H50c1,0,1.5,1,1.5,2V39h-9V28.7C42.5,27.4,42.9,26.7,43.7,26.7ZM57.1,58a2,2,0,0,1-1.9,2H38.8a2,2,0,0,1-1.9-2V45a2,2,0,0,1,1.9-2H55.2a2,2,0,0,1,1.9,2ZZ" />
      <path d="M47.2,46.3A3.2,3.2,0,0,0,44,49.5a3.1,3.1,0,0,0,2.1,3v3.3a1.2,1.2,0,0,0,1.1,1.3h0a1.3,1.3,0,0,0,1.3-1.3V52.5a3,3,0,0,0,2-3A3.3,3.3,0,0,0,47.2,46.3ZZ" />
    </g>
  </SvgIcon>
);

export default DoorLockdownIcon;
