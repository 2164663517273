import React, { useMemo } from "react";

interface ProgressCircleProps {
  progress: number;
  size?: number;
  strokeWidth?: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  progress,
  size = 32,
  strokeWidth = 4,
}) => {
  const radius = useMemo(() => (size - strokeWidth) / 2, [size, strokeWidth]);
  const circumfrance = useMemo(() => 2 * radius * Math.PI, [radius]);
  const offset = circumfrance - (progress / 100) * circumfrance;

  return (
    <svg width={size} height={size} style={{ transform: "rotate(-90deg)" }}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="white"
        strokeWidth={strokeWidth}
        className="progress-circle_background"
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="#218ebe"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeDasharray={circumfrance}
        strokeDashoffset={offset}
        className="progress-circle_progress"
        transform="rotate(0 16 16)"
      >
        <animateTransform
          attributeName="transform"
          begin="0s"
          dur="3s"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default ProgressCircle;
