/**
 *
 * Table
 * @author Chad Watson
 *
 */

import { BASE_VERTICAL_PADDING_LARGE_IN_REM } from "constants/index";
import { themeGrayAccent, themeTrueBlack } from "containers/AppTheme";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components/macro";

// TODO: Make this a more helpful component
/** @type {(props: { headers?: React.ReactNode[] | JSX.Element[], footers?: React.ReactNode[] | JSX.Element[], children: React.ReactNode[] | JSX.Element[] } & Parameters<typeof NormalizedTable>[0]) => JSX.Element} */
const Table = ({ headers, children, footers, ...rest }) => (
  <NormalizedTable {...rest}>
    <thead>
      {!!headers && (
        <HeaderRow>
          {headers.map((header, index) =>
            React.cloneElement(header, { key: index.toString() })
          )}
        </HeaderRow>
      )}
    </thead>

    <tbody>{children}</tbody>

    <tfoot>
      {!!footers && (
        <tr>
          {footers.map((footer, index) =>
            React.cloneElement(footer, { key: index.toString() })
          )}
        </tr>
      )}
    </tfoot>
  </NormalizedTable>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
  footers: PropTypes.node,
  rows: PropTypes.arrayOf(PropTypes.array),
  headers: PropTypes.arrayOf(PropTypes.node),
};

export default Table;

export const NormalizedTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border: none;
  color: ${themeTrueBlack};
  font-weight: 400;
`;
export const HeaderRow = styled.tr`
  border-bottom: 2px solid ${themeGrayAccent};
  padding-bottom: ${BASE_VERTICAL_PADDING_LARGE_IN_REM}rem;
`;
