import { BORDER_RADIUS } from "constants/index";
import { themePanelBorder } from "containers/AppTheme";
import styled, { css } from "styled-components/macro";

const FormWrapper = styled.div<{ disabled?: boolean; hasActionRow?: boolean }>`
  width: 100%;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  background: white;

  ${({ disabled }) =>
    disabled &&
    css`
      position: relative;
      pointer-events: none;

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        opacity: 0.3;
        z-index: 50;
      }
    `};
`;
export default FormWrapper;
