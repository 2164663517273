import NakedButtonLink from "components/NakedButton/NakedButtonLink";
import { BORDER_RADIUS } from "constants/index";
import {
  themeGrayDark,
  themePanelBorder,
  themeTrueBlack,
} from "containers/AppTheme";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components/macro";
import { media } from "utils/styles";

const Button = styled(({ space, ...rest }: { to: string; space?: boolean }) => (
  <NakedButtonLink {...rest} />
))`
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  padding: 0 15px;
  border: ${themePanelBorder};
  border-radius: ${BORDER_RADIUS};
  background: white;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.06em;
  white-space: pre;
  color: ${themeTrueBlack};

  ${({ space }) =>
    space &&
    css`
      &:not(:last-child) {
        margin-right: 5px;
      }
    `} &[disabled] {
    color: ${themeGrayDark};
  }

  &:enabled {
    color: ${themeTrueBlack};

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: scale(1.02);
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
    }
  }

  ${media.smallMax`
    flex: 1;

    & button {
      width: 100%;
    }
  `};
`;

Button.propTypes = {
  space: PropTypes.bool,
};

Button.defaultProps = {
  space: false,
};

const InnerWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Wrapper = styled.span`
  display: block;
  line-height: 0;
`;
const IconWrapper = styled(Wrapper)`
  margin-right: 0.45em;
  font-size: 1.2em;
`;

const ActionButtonLink = ({
  icon,
  children,
  ...rest
}: {
  to: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}) => (
  <Button {...rest}>
    <InnerWrapper>
      {!!icon && <IconWrapper>{icon}</IconWrapper>}
      <Wrapper>{children}</Wrapper>
    </InnerWrapper>
  </Button>
);

export default ActionButtonLink;
