/**
 *
 * Errors
 * @author Chad Watson
 *
 */

import { AttentionIcon, CloseCancelIcon } from "components/Icons";
import NakedButton from "components/NakedButton";
import { BORDER_RADIUS, GUTTER } from "constants/index";
import { themeWarning } from "containers/AppTheme";
import PropTypes from "prop-types";
import React from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { capitalize } from "utils";
import messages from "./messages";

const Errors = ({ errors, title, className, onClose }) => (
  <Root className={className}>
    {!!title && (
      <Header>
        <Title>
          <Icon />
          &nbsp;
          <TitleText>
            {title === true ? (
              <FormattedMessage {...messages.errorsTitle} />
            ) : (
              title
            )}
          </TitleText>
        </Title>
        {onClose && (
          <CloseButton onClick={onClose}>
            <CloseCancelIcon />
          </CloseButton>
        )}
      </Header>
    )}
    {!!errors.size && (
      <List>
        {errors.map((error, i) => (
          <Error key={i}>
            <ErrorText>
              {typeof error !== "string" && error.get("id") ? (
                <FormattedMessage {...error.toJS()} />
              ) : (
                capitalize(error)
              )}
            </ErrorText>
          </Error>
        ))}
      </List>
    )}
  </Root>
);

Errors.propTypes = {
  errors: ImmutablePropTypes.listOf(
    PropTypes.oneOfType([
      PropTypes.string,
      ImmutablePropTypes.contains({
        id: PropTypes.string,
      }),
    ])
  ),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  className: PropTypes.string,
  onClose: PropTypes.func,
};

Errors.defaultProps = {
  title: true,
};

export default Errors;

const Root = styled.section`
  margin-bottom: ${GUTTER}px;
  border-radius: ${BORDER_RADIUS};
  border: 1px solid ${themeWarning};
  overflow: hidden;
  background: white;
`;
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: ${themeWarning};
`;
const Title = styled.h3`
  margin: 0;
  color: white;
  line-height: 1.1;
`;
const Icon = styled(AttentionIcon)`
  margin-right: 0.1em;
  vertical-align: middle;
  font-size: 1.1em;
`;
const TitleText = styled.span`
  vertical-align: middle;
  font-weight: 600;
`;
const List = styled.ul`
  margin: 0;
  padding: 15px 20px;
  list-style: none;
`;
const Error = styled.li`
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;

  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0.5em;
    height: 0.5em;
    margin-right: 0.7em;
    border-radius: 50%;
    background: ${themeWarning};
  }
`;
const ErrorText = styled.span`
  vertical-align: middle;
`;
const CloseButton = styled(NakedButton)`
  padding: 0 10px;
  color: white;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`;
