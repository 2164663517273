/**
 *
 * Button
 * @author: Alex Matthews
 *
 */
import {
  BASE_VERTICAL_PADDING_IN_REM,
  BORDER_RADIUS,
  CUBIC_BEZIER,
} from "constants/index";
import {
  themeBackgroundColor,
  themeBorderColor,
  themeGrayDark,
  themeTextColor,
} from "containers/AppTheme";
import * as React from "react";
import styled, { css, ThemeProvider } from "styled-components/macro";
export { default as ButtonWithIcon } from "./ButtonWithIcon";
export { default as DestructiveButton } from "./DestructiveButton";
export { default as DoneButton } from "./DoneButton";
export { default as DullButton } from "./DullButton";
export { default as SecondaryButton } from "./SecondaryButton";
export { default as SuccessButton } from "./SuccessButton";

const shapes = {
  default: css`
    padding: ${({ size }) =>
      size === "small"
        ? `${BASE_VERTICAL_PADDING_IN_REM}rem 18px`
        : `${BASE_VERTICAL_PADDING_IN_REM}rem 25px`};
    border-radius: ${BORDER_RADIUS};
  `,
  round: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4em;
    height: 4em;
    padding: 0;
    border-radius: 9999px;
    line-height: 4em;
  `,
};
const symbol = css`
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 0.7em;
  height: 0.7em;
  border-left: 1px solid;
  border-bottom: 1px solid;
`;
export const BaseButton = styled.button`
  position: relative;
  display: inline-block;
  color: ${themeTextColor};
  background: ${themeBackgroundColor};
  border: 1px solid ${themeBorderColor};
  font-family: inherit;
  font-size: ${({ size }) => (size === "small" ? "13px" : "inherit")};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.04em;
  line-height: 1;

  ${({ prev }) =>
    prev &&
    css`
      &::before {
        ${symbol} left: 15px;
        transform: translateY(-50%) rotate(45deg);
      }
    `} ${({ next }) =>
    next &&
    css`
      &::after {
        ${symbol} right: 15px;
        transform: translateY(-50%) rotate(-135deg);
      }
    `}

  &::selection {
    background: transparent;
    color: inherit;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:not([disabled]) {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.elevation0};
    transition: 0.25s box-shadow ${CUBIC_BEZIER};

    &:hover {
      transform: scale(1.01);
      box-shadow: ${({ theme }) => theme.elevation200};
    }

    &:active {
      transform: scale(1.02);
      box-shadow: ${({ theme }) => theme.elevation200};
    }
  }

  ${({ variant }) =>
    variant === "outline"
      ? css`
          &[disabled] {
            opacity: 0.4;
            cursor: not-allowed;
          }
        `
      : css`
          &[disabled] {
            background: ${themeGrayDark};
            border-color: ${themeGrayDark};
            cursor: not-allowed;
          }
        `} ${({ shape }) => shapes[shape]};
`;
BaseButton.defaultProps = {
  shape: "default",
  variant: "default",
  size: "default",
  next: false,
  prev: false,
};
export const UnthemedButton = React.forwardRef((props, ref) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      textColor:
        props.variant === "outline" || props.inverted
          ? currentTheme.color
          : currentTheme.opposedColor,
      backgroundColor:
        props.variant === "outline" || props.inverted
          ? currentTheme.trueWhite
          : currentTheme.color,
      borderColor: props.inverted
        ? currentTheme.opposedColor
        : currentTheme.color,
    })}
  >
    <BaseButton {...props} ref={ref}>
      {props.children}
    </BaseButton>
  </ThemeProvider>
));

/** @type {(props: {
 * shape?: "default" | "round";
 * variant?: "default" | "outline";
 * size?: "default" | "small";
 * next?: boolean;
 * prev?: boolean;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>) => JSX.Element} */
const Button = (props) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.secondary,
      opposedColor: currentTheme.trueWhite,
    })}
  >
    <UnthemedButton {...props} />
  </ThemeProvider>
);

export default Button;
