import {
  AnimatedCycleIcon,
  RadialAlertIcon,
  RadialCheckIcon,
} from "components/Icons";
import LoadingSpinner from "components/LoadingSpinner";
import {
  themeSuccess,
  themeTrueBlack,
  themeWarning,
} from "containers/AppTheme";
import React from "react";
import { MessageDescriptor } from "react-intl";
import styled from "styled-components/macro";
import Indicator from "./Indicator";

export { default as Indicator } from "./Indicator";

const StyledRadialAlertIcon = styled(RadialAlertIcon)`
  color: ${themeWarning};
`;

const GreenRadialCheckIcon = styled(RadialCheckIcon)`
  color: ${themeSuccess};
`;

const LightRadialCheckIcon = styled(RadialCheckIcon)`
  color: ${themeTrueBlack};
`;

const Indicators = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const STATUSES = {
  ERROR: "error",
  LOADING: "loading",
  SUCCESS: "success",
  SUCCESS_PENDING: "successPending",
  FALLBACK: "fallback",
  RELOADING: "reloading",
};

const ReloadingIcon = () => <AnimatedCycleIcon animated />;

const STATUS_ICONS = {
  [STATUSES.ERROR]: StyledRadialAlertIcon,
  [STATUSES.LOADING]: LoadingSpinner,
  [STATUSES.SUCCESS]: GreenRadialCheckIcon,
  [STATUSES.SUCCESS_PENDING]: LightRadialCheckIcon,
  [STATUSES.RELOADING]: ReloadingIcon,
};

type Props = {
  className?: string;
  message?: string | MessageDescriptor;
  error?: boolean | React.ReactNode;
  loading?: boolean | React.ReactNode;
  success?: boolean | React.ReactNode;
  success_pending?: boolean | React.ReactNode;
  reloading?: boolean | React.ReactNode;
  fallback?: React.ReactNode;
};

const getIndicators = (props: Props) =>
  Object.keys(props)
    .filter((key) => !!props[key] && !!STATUS_ICONS[key])
    .map((key) => {
      return (
        <Indicator
          key={key}
          icon={STATUS_ICONS[key]}
          message={
            React.isValidElement(props[key]) || typeof props[key] === "string"
              ? props[key]
              : null
          }
        />
      );
    });

const StatusIndicators = ({ className, message, ...rest }: Props) => {
  const indicators = getIndicators(rest);

  return (
    <Indicators className={className}>
      {!!indicators.length && indicators}
      {!indicators.length && rest[STATUSES.FALLBACK] && (
        <Indicator icon={rest[STATUSES.FALLBACK]} message={message} />
      )}
    </Indicators>
  );
};

export default StatusIndicators;
