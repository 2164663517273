/**
 *
 * DullButton
 * @author Chad Watson
 *
 */
import * as React from "react";
import { ThemeProvider } from "styled-components/macro";
import { BaseButton, UnthemedButton } from "./index";
const OutlineDullButton = React.forwardRef((props, ref) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      textColor: currentTheme.trueBlack,
      borderColor: currentTheme.grayAccent,
    })}
  >
    <BaseButton {...props} ref={ref} />
  </ThemeProvider>
));
const StandardDullButton = React.forwardRef((props, ref) => (
  <ThemeProvider
    theme={(currentTheme) => ({
      ...currentTheme,
      color: currentTheme.grayAccent,
      opposedColor: currentTheme.grayDark,
    })}
  >
    <UnthemedButton {...props} ref={ref} />
  </ThemeProvider>
));
/** @type {React.FC<{variant?: string; size?: string; onClick: () => {}}>} */
const DullButton = React.forwardRef((props, ref) =>
  props.variant === "outline" ? (
    <OutlineDullButton {...props} ref={ref} />
  ) : (
    <StandardDullButton {...props} ref={ref} />
  )
);
export default DullButton;
