/**
 *
 * TimeField
 * @author Chad Watson
 *
 */
import { Dropdown, UncontrolledDropdown } from "components/Dropdown";
import Switch from "components/Switch";
import { TimePickerFromDateString } from "components/TimePicker";
import { BORDER_RADIUS } from "constants/index";
import {
  themeBorderColor,
  themeTrueBlack,
} from "containers/AppTheme";
import { useFormattedMessage, useIntl } from "hooks/useIntl";
import { Seq } from "immutable";
import messages from "models/schedules/messages";
import {
  SLOT_END_TYPE_OPTIONS,
  SLOT_TYPE_OPTIONS,
} from "models/schedules/translate";
import moment from "moment";
import {
  always,
  complement,
  concat,
  cond,
  converge,
  defaultTo,
  either,
  equals,
  gt,
  ifElse,
  isEmpty,
  isNil,
  join,
  lensProp,
  propSatisfies,
  T,
  toString,
  trim,
  unapply,
  view,
  when,
  __,
} from "ramda";
import React from "react";
import { FormattedMessage } from "react-intl";
import { compose, defaultProps, lifecycle, withHandlers } from "recompose";
import styled, { css } from "styled-components/macro";
import { capitalize } from "utils";
import FieldWithPicker from "./FieldWithPicker";
import formMessages from "./messages"; // ---------------------------
// Types
// ---------------------------

// ---------------------------
// Styled Components
// ---------------------------
const Root = styled.div`
  position: relative;
`;
const OptionsContainer = styled.div`
  min-width: 210px;
  padding: 10px;

  &:first-child {
    border-bottom: 1px solid ${themeBorderColor};
  }
`;
const OptionSwitches = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionSwitch = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 5px 3px 0;
  margin: 5px;
  border-radius: ${BORDER_RADIUS};
  color: ${themeTrueBlack};
  text-align: center;

  ${({ disabled, theme }) =>
    !disabled ?
    css`
      cursor: pointer;
    ` : css`
      color: ${theme.grayDark};
    `};
`;
const OptionSwitchText = styled.span`
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
`;
const DayOptionDropdown = styled(Dropdown)`
  margin-top: 4px;
`;
const SelectedDayLabel = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0 3px;
  transform: translate(-50%, -35%);
  color: ${themeTrueBlack};
  background: white;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.04em;
  white-space: nowrap;
`; // ---------------------------
// Custom Hooks
// ---------------------------

const useTimeOffsetOptions = () => {
  const { formatMessage } = useIntl();
  return [-60, -45, -30, -15, -10, -5, 0, 5, 10, 15, 30, 45, 60].map(
    (value) => ({
      value,
      label:
        value > 0
          ? formatMessage(messages.minutesAfter, {
              amount: Math.abs(value).toString(),
            })
          : value < 0
          ? formatMessage(messages.minutesBefore, {
              amount: Math.abs(value).toString(),
            })
          : formatMessage(messages.noOffset),
    })
  );
}; // Get Internationalized Day Options

const useDayOptions = () => [
  {
    [SLOT_END_TYPE_OPTIONS.NEXT_DAY]: useFormattedMessage(
      messages[SLOT_END_TYPE_OPTIONS.NEXT_DAY]
    ),
    [SLOT_END_TYPE_OPTIONS.SUN]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.SUN]
    ),
    [SLOT_END_TYPE_OPTIONS.MON]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.MON]
    ),
    [SLOT_END_TYPE_OPTIONS.TUE]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.TUE]
    ),
    [SLOT_END_TYPE_OPTIONS.WED]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.WED]
    ),
    [SLOT_END_TYPE_OPTIONS.THU]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.THU]
    ),
    [SLOT_END_TYPE_OPTIONS.FRI]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.FRI]
    ),
    [SLOT_END_TYPE_OPTIONS.SAT]: useFormattedMessage(
      messages[SLOT_TYPE_OPTIONS.SAT]
    ),
  },
  [
    {
      label: useFormattedMessage(messages[SLOT_END_TYPE_OPTIONS.SAME_DAY]),
      value: SLOT_END_TYPE_OPTIONS.SAME_DAY,
    },
    {
      label: useFormattedMessage(messages[SLOT_END_TYPE_OPTIONS.NEXT_DAY]),
      value: SLOT_END_TYPE_OPTIONS.NEXT_DAY,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.SUN]),
      value: SLOT_END_TYPE_OPTIONS.SUN,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.MON]),
      value: SLOT_END_TYPE_OPTIONS.MON,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.TUE]),
      value: SLOT_END_TYPE_OPTIONS.TUE,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.WED]),
      value: SLOT_END_TYPE_OPTIONS.WED,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.THU]),
      value: SLOT_END_TYPE_OPTIONS.THU,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.FRI]),
      value: SLOT_END_TYPE_OPTIONS.FRI,
    },
    {
      label: useFormattedMessage(messages[SLOT_TYPE_OPTIONS.SAT]),
      value: SLOT_END_TYPE_OPTIONS.SAT,
    },
  ],
];

const useTwilightOptions = () => [
  {
    SUNRISE: useFormattedMessage(messages.sunrise),
    SUNSET: useFormattedMessage(messages.sunset),
  },
  {
    SUNRISE: "sunrise",
    SUNSET: "sunset",
  },
]; // ---------------------------
// Helpers
// ---------------------------

const formatDateString = (dateString) => moment(dateString).utc().format("LT");

const dateStringLens = lensProp("dateString");
const twilightPeriodLens = lensProp("twilightPeriod");
const twilightOffsetLens = lensProp("twilightOffset");
const twilightPeriodDisplayValue = compose(
  capitalize,
  view(twilightPeriodLens)
);
const twilightOffsetDisplayValue = compose(
  ifElse(
    equals(0),
    always(""),
    when(gt(__, 0), compose(concat("+"), toString))
  ),
  defaultTo(0),
  view(twilightOffsetLens)
);
const twilightDisplayValue = compose(
  trim,
  converge(unapply(join(" ")), [
    twilightPeriodDisplayValue,
    twilightOffsetDisplayValue,
  ])
);
const timeDisplayValue = compose(formatDateString, view(dateStringLens));
const displayValue = cond([
  [
    propSatisfies(complement(either(isEmpty, isNil)), "twilightPeriod"),
    twilightDisplayValue,
  ],
  [
    propSatisfies(complement(either(isEmpty, isNil)), "dateString"),
    timeDisplayValue,
  ],
  [T, always("")],
]);

const getTwilightLabel = (value, labels) =>
  typeof value === "string" ? labels[value.toUpperCase()] : undefined; // ---------------------------
// Component
// ---------------------------

const TimeField = ({
  id,
  dateString,
  defaultDateString,
  twilightPeriod,
  twilightOffset,
  day,
  error,
  className,
  onTwilightPeriodChange,
  onTwilightOffsetChange,
  onTimeSwitchToggle,
  onDayOptionChange,
  onClear,
  onOpen,
  onTimeChange,
  withTwilightOptions,
  utc,
  placeholder,
  zIndex,
  dismissOnScroll,
}) => {
  // ---------------------------
  // Statics
  // ---------------------------
  const TWILIGHT_OFFSET_OPTIONS = useTimeOffsetOptions();
  const [TWILIGHT_LABELS, TWILIGHT_PERIODS] = useTwilightOptions();
  const [dayLabels, dayOptions] = useDayOptions(); // ---------------------------
  // Derrived State
  // ---------------------------

  const fieldValue = displayValue({
    dateString,
    twilightPeriod: getTwilightLabel(twilightPeriod, TWILIGHT_LABELS),
    twilightOffset,
  });
  const selectedDayMessage = day && dayLabels[day] ? dayLabels[day] : "";
  return (
    <Root>
      <FieldWithPicker
        id={id}
        value={fieldValue}
        error={error}
        className={className}
        onClear={onClear}
        onOpen={onOpen}
        placeholder={placeholder}
        zIndex={zIndex}
        dismissOnScroll={dismissOnScroll}
      >
        {withTwilightOptions && (
          <OptionsContainer>
            <OptionSwitches>
              <OptionSwitch htmlFor={`${id}-time`}>
                <Switch
                  id={`${id}-time`}
                  checked={!twilightPeriod && !!dateString}
                  onChange={onTimeSwitchToggle}
                  small
                />
                <OptionSwitchText>
                  <FormattedMessage {...formMessages.time} />
                </OptionSwitchText>
              </OptionSwitch>
              <OptionSwitch htmlFor={`${id}-${TWILIGHT_PERIODS.SUNRISE}`}>
                <Switch
                  id={`${id}-${TWILIGHT_PERIODS.SUNRISE}`}
                  value={TWILIGHT_PERIODS.SUNRISE}
                  checked={twilightPeriod === TWILIGHT_PERIODS.SUNRISE}
                  onChange={onTwilightPeriodChange}
                  small
                />
                <OptionSwitchText>{TWILIGHT_LABELS.SUNRISE}</OptionSwitchText>
              </OptionSwitch>
              <OptionSwitch htmlFor={`${id}-${TWILIGHT_PERIODS.SUNSET}`}>
                <Switch
                  id={`${id}-${TWILIGHT_PERIODS.SUNSET}`}
                  value={TWILIGHT_PERIODS.SUNSET}
                  checked={twilightPeriod === TWILIGHT_PERIODS.SUNSET}
                  onChange={onTwilightPeriodChange}
                  small
                />
                <OptionSwitchText>{TWILIGHT_LABELS.SUNSET}</OptionSwitchText>
              </OptionSwitch>
            </OptionSwitches>
          </OptionsContainer>
        )}
        {(!withTwilightOptions || (!twilightPeriod && dateString)) && (
          <OptionsContainer>
            <TimePickerFromDateString
              id={id}
              dateString={dateString || defaultDateString}
              onChange={onTimeChange}
              utc={utc}
            />
            {day && (
              <DayOptionDropdown
                id={`${id}-day`}
                options={dayOptions}
                selectedIndex={dayOptions.findIndex(
                  ({ value }) => value === day
                )}
                onChange={onDayOptionChange}
              />
            )}
          </OptionsContainer>
        )}
        {!!twilightPeriod && (
          <OptionsContainer>
            <UncontrolledDropdown
              id={`${id}-twilight-offset`}
              options={TWILIGHT_OFFSET_OPTIONS}
              defaultIndex={TWILIGHT_OFFSET_OPTIONS.findIndex(
                (option) => option.value === (twilightOffset || 0)
              )}
              onChange={onTwilightOffsetChange}
            />
          </OptionsContainer>
        )}
      </FieldWithPicker>
      {dateString && !twilightPeriod && !!selectedDayMessage && (
        <SelectedDayLabel>{selectedDayMessage}</SelectedDayLabel>
      )}
    </Root>
  );
};

const enhance = compose(
  defaultProps({
    dateString: "",
    twilightPeriod: "",
    twilightOffset: 0,
    defaultDateString: moment
      .utc({
        hours: 8,
        minutes: 0,
        seconds: 0,
      })
      .toISOString(),
    withTwilightOptions: false,
  }),
  withHandlers({
    onTimeSwitchToggle:
      ({ onTimeChange, defaultDateString }) =>
      ({ currentTarget }) => {
        onTimeChange(currentTarget.checked ? defaultDateString : "");
      },
    onTwilightPeriodChange:
      ({ onTwilightPeriodChange, twilightPeriod }) =>
      ({ currentTarget }) => {
        onTwilightPeriodChange(
          twilightPeriod === currentTarget.value ? "" : currentTarget.value
        );
      },
    onTwilightOffsetChange:
      ({ onTwilightOffsetChange }) =>
      ({ value }) =>
        onTwilightOffsetChange(value),
    onDayOptionChange:
      ({ onDayChange }) =>
      ({ value }) =>
        onDayChange(value),
    onOpen:
      ({ onTimeChange, dateString, defaultDateString, withTwilightOptions }) =>
      () => {
        if (!withTwilightOptions && !dateString) {
          onTimeChange(defaultDateString);
        }
      },
  }),
  lifecycle({
    shouldComponentUpdate(nextProps) {
      return Seq(nextProps).some((v, k) => v !== this.props[k]);
    },
  })
);
export default enhance(TimeField);
